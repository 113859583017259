body {  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./img/starTile.png");
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Space Mono', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  margin: 0 auto;
  max-width: 1100px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (min-width: 1100px)
{
  body {
    padding: 50px;
    width: 1100px;
  }
}
