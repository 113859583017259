.navigation__arrow {
    height: 14px;
    margin-left: 10px;
    vertical-align: middle;
}

.navigation__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: min-content;
}

.navigation__link {
    text-decoration: none;
    vertical-align: middle;
}

.navigation__list {
    font-style: italic;
    margin-top: 0;
    width: 70%;
}

.navigation__logo {
    width: 50%;
}

.navigation__nav {    
    background-color: white;
    box-shadow: 12px 12px #FFC6DE;
    float: left;
    padding: 0 12px 16px 12px;    
    position: relative;
    width: auto;
    z-index: 10;
}

.navigation__title {
    color: #634B66;
    font-family: 'Press Start 2P';
}

@media (max-width: 900px)
{
    .navigation__nav {
        display: none;
    }
}