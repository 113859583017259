.home__badges{
    display: flex;
    flex-direction: column;
    padding: 12px;
    align-items: center;
}

.home__badges img{
    height: 100px;
}

.home__feature {
    display: grid;
    margin-bottom: 150px;
    overflow: hidden;
    width: 100%;
}

.home__featureInfo {
    background-color: white;
    display: grid;
    margin-bottom: 50px;
    text-align: center;
}

.home__featureInfo h4 {
    align-self: flex-start;    
    color: #634B66;
    font-family: 'Press Start 2P';
}

.home__featureInfo p {    
    box-sizing: border-box;
    margin-top: 0;
    padding: 16px;
    text-align: left;
}

.home__featureVideo {
    height: 0;
    margin-bottom: 50px;
    padding-bottom: 56.25%;
    position: relative;
}

.home__featureVideo iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.home__miso {
    justify-self: end;
    margin-bottom: -100px;
    margin-right: -80px;
    width: 250px;
    z-index: 1;
}

.home__somi {
    justify-self: start;
    margin-left: -40px;
    margin-top: -150px;
    width: 250px;
    z-index: 1;
}

@media (min-width: 900px)
{
    .home__badges{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .home__badges p {
        margin: 0 15px;
    }

    .home__feature {
        display: inline-grid;
        justify-self: end;
        margin-left: 75px;
        margin-top: -80px;
        max-width: calc(100% - 260px);
        vertical-align: top;
    }

    .home__featureInfo {        
        box-shadow: 12px 12px #FFC6DE;
        padding: 25px;
        padding-top: 0;
    }

    .home__featureVideo {
        margin-bottom: 0;
    }
}