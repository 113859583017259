.about__fightingCats {
    width: 100%;    
}

.about__serinus {
    padding-left: 12%;
}

.about__teamMember {
    display: inline-block;
}

.about__teamMember h5, h6, ul {
    font-size: small;
    font-weight: 400;    
    margin: 0;
}

.about__teamMember h6{
    font-style: italic;
}

.about__sleepingCats {
    margin-left: -16px;
    margin-top: -5%;    
    width: calc(100% + 32px);
}

.about__team {
    background-color: white;
    box-sizing: border-box;
    box-shadow: 0 12px #FFC6DE;
    display: inline-grid;
    font-size: 0.8rem;
    margin-top: 50px;
    padding: 16px;
    width: 100%;
}

.about__team h4 {
    color: #634B66;
    font-family: 'Press Start 2P';
    text-align: center;
}

.about__yoobs {
    justify-self: right;
    margin-top: -10%;
    padding-bottom: 5%;
    padding-right: 35%;
}

@media (min-width: 900px)
{
    .about__fightingCats {
        margin-left: 12px;
        margin-top: 50px;
        vertical-align: top;
        width: 40%;    
    }

    .about__serinus {
        padding-left: 25px;
    }

    .about__sleepingCats {
        margin-top: -50px;
    }

    .about__team {
        box-shadow: 12px 12px #FFC6DE;
        width: 50%;
    }

    .about__yoobs {
        padding-right: 150px;
    }
}