.featureCard__main {
    background-color: white;
    box-shadow: 0 12px #FFC6DE;
    margin-top: 50px;
    padding: 5% 0;
    text-align: center;
    width: 100%;
}

.featureCard__main a {
    text-decoration: none;
}

.featureCard__main h4 {
    color: #634B66;
    font-family: 'Press Start 2P';
    margin-top: 0;
}

.featureCard__main p {
    padding: 0 50px;
    text-align: left;
}

.featureCard__main ul {
    padding: 16px;
    text-align: left;
}

.featureCard__main ul li {
    list-style-position: inside;
}

@media (min-width: 900px)
{
    .featureCard__main {
        box-shadow: 12px 12px #FFC6DE;
        display: inline-grid;
        justify-self: end;
        margin-left: 75px;
        max-width: calc(100% - 260px);
        vertical-align: bottom;
    }

    .featureCard__main p {
        text-align: center;
    }

    .featureCard__main ul {
        margin: auto;
    }
}

@media (min-width: 1100px)
{
    .featureCard__main {
        width: 70%;
    }
}