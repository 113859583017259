.app__hamburger {
  align-self: flex-end;
  background-color: transparent;
  color: black;
  padding: 14px 16px;
  width: 10%;
}

.app__hamburger:hover {
  cursor: pointer;
}

.app__hamburgerWhite {
  background-color: white;
}

.app__header {
  align-items: center;
  background-color: #FFC6DE;
  color: white;
  display: flex;
  flex-wrap: wrap;
  font-size: 1rem;  
  font-weight: bold;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 5;
}

.app__header p {
  flex-grow: 1;
  margin: 0;
  padding: 0 16px;
}

.app__link {
  padding-left: 10px;
  text-decoration: none;
}

.app__linksContainer{
  background-color: white;
  font-family: 'Space Mono';
  font-size: 1.25rem;
  font-style: italic;
  padding: 10px 5px 5px;
  width: 100%;
}

.app__links {
  list-style: none;
  padding: 0;
}

.hidden {
  display: none;
}

@media (min-width: 500px)
{
  .app__header {
    font-size: 2rem;
    margin-bottom: 50px;
  }
}

@media (min-width: 800px)
{
  .app__header {
    font-size: 3rem;
    margin-bottom: 50px;
  }
}

@media (min-width: 900px)
{
  .app__hamburger, #app__links {
    display: none;
  }

  .app__header {
    text-align: center;
  }
}