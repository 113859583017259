.privacyPolicy__noImage {
    background-image: none;
}

.privacyPolicy__readme {
    padding: 16px;
}

@media (min-width: 1100px)
{
    .privacyPolicy__readme {
        padding: 0;
    }
}