.contact__container {
    position: relative;
    overflow: hidden;
}

.contact__flexwrap {
    flex-wrap: wrap;
}

.contact__formContainer {
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
}

.contact__formContainer input {
    flex-grow: 1;
}

.contact__formContainer label {
    border-bottom: 1px solid black;
    border-left: 1px solid black;
    border-top: 1px solid black;
    padding-left: 5px;
}

.contact__form input {
    border-left: none;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}

.contact__message {
    display: flex;
    flex-direction: column;
    padding-top: 5px;
}

.contact__message label{
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-top: 1px solid black;
    box-sizing: border-box;
    padding: 5px;
    text-align: left;
    width: 100%;
}

.contact__message textarea {
    border-top: none;
    box-sizing: border-box;
    padding: 5px;
    resize: none;
    width: 100%;
}

.contact__miso {
    display: block;
    position: relative;
    margin-bottom: -65px;
    width: 250px;
    z-index: 1;
}

.contact__name, .contact__email {
    display: flex;
    padding: 5px 0;
    width: 100%;
}

.contact__somi {
    display: block;
    margin-left: auto;
    margin-right: 0;
    position: relative;
    width: 200px;
    z-index: -1;
}

.contact__submit {
    background-color: white;
    border: 1px solid black;
    font-size: 1rem;
    margin-top: 10px;
    padding: 8px 20px;
}

.contact__submit:hover {
    cursor: pointer;
}

@media (min-width: 900px)
{
    .contact__container {
        position: relative;
        overflow: visible;
    }
    

    .contact__email {
        padding-left: 5px;
    }

    .contact__flexwrap {
        flex-wrap: nowrap;
    }

    .contact__form {
        padding: 0 50px;
    }

    .contact__formContainer {
        padding: 5px 0;
    }

    .contact__miso {
        position: absolute;
        right: 5px;
        top: -50px;
        width: 250px;
        z-index: 1;
    }

    .contact__name {
        padding-right: 5px;
    }

    .contact__name, .contact__email {
        width: 50%;
    }

    .contact__somi {
        position: absolute;
        left: 60px;
        bottom: 0;
        width: 200px;
        z-index: -1;
    }
    
}